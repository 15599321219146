window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};

(() => {
	Object.defineProperty(window, 'midiaSize', {
		get() {
			var w = window.innerWidth;
			var size = 'xs';

			if(w < 500) {
				size = 'xs';
			}

			if(w >= 500) {
				size = 'sm';
			}

			if(w >= 768) {
				size = 'md';
			}

			if(w >= 992) {
				size = 'lg';
			}

			if(w >= 1200) {
				size = 'xl';
			}

			return size;
		}
	})
})();

const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function basePath () {
	let p = '';

	if($('body').data('path')) {
		p = $('body').data('path') + '/';
	}

	return p;
}
