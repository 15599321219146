// 2. This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.

function onYouTubeIframeAPIReady() {
	let count = 0;

	$('[data-is="video-youtube"]').each(function() {
		let $this = $(this);
		let videoId =  extractVideoID($this.data('href'))

		$this.append(`<div class="ytb-container" id="video-youtube-${count}">
			<img src="http://img.youtube.com/vi/${videoId}/maxresdefault.jpg"/>
			<button class="ytb-play btn">
				<span class="sr-only">Play video</span>
			</button>
		</div>`);

		let img    = $this.find('img');
		let button = $this.find('.btn');

		img.on('error', function() {
			this.src = `http://img.youtube.com/vi/${videoId}/sddefault.jpg`;
		});

		img.on('contextmenu', function(e) {
			e.preventDefault();
			return false;
		});

		button.click(function() {
			// $this.html(`<iframe src="https://youtube.com/embed/${videoId}?autoplay=1" id="video-youtube-${count}"></iframe>`)
			let player = new YT.Player(
				`video-youtube-${count}`,
				{
					videoId : videoId,
					events: {
						onReady: onPlayerReady
					}
				}
			);

			$this.parents('.carousel').on('slide.bs.carousel', function() {
				player.pauseVideo();
			})

			count++;
		});
	});
}

// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
	event.target.playVideo();
}

// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.
var done = false;
function onPlayerStateChange(event) {
	if (event.data == YT.PlayerState.PLAYING && !done) {
		setTimeout(stopVideo, 6000);
		done = true;
	}
}
function stopVideo() {
	player.stopVideo();
}


function extractVideoID(url) {
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

	var match = url.match(regExp);
	if ( match && match[7].length == 11 ){
			return match[7];
	}else {
		console.log(`Não foi possível extrair o ID do vídeo: ${url}`);
	}
}


(function() {
	let videos = $('#banner__carousel video');

	$('#banner__carousel').on('slide.bs.carousel', function() {
		videos.each(function() {
			this.pause();
		});
	});
})();
