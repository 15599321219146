var ViewportAnimation = (function() {
	var animator = new IntersectionObserver(function(entries) {
		$(entries).each(function(index, entry) {

			if(entry.isIntersecting) {
				var $target		= $(entry.target)
				var animation 	= $target.data('animate');

				$target
					.addClass(animation)
					.removeAttr('data-animate');

				animator.unobserve(entry.target);
			}
		});
	}, {
		rootMargin: '-50px -50px -50px -50px'
	});


	$('[data-animate]').each(function() {
		animator.observe(this);
	});
})();
